<template>
<div>
     <vs-alert color="danger" class="mb-4" title="Your email address is not verified! Verify to be notified when your order is delivered" >
       <div class="py-3">
            <span class="mr-4">Kindly check your inbox to verify. If in anyway you didn't receive email, </span>
            <a class="" target="_blank"  :href="absoluteUrl(`/email/${activeUser.email}/verify`)">Click to verify now!</a>
        </div>
    </vs-alert>
</div>
</template>

<script>
export default {
    computed: {
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    }
}
</script>
